.navbar {
  display: flex;
  flex-wrap: wrap;
}

.navbar a {
  text-decoration: none;
  color: #fff;
}

.logo {
  margin-right: 50px;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-list {
  list-style: none;
  width: 100%;
  display: flex;
  position: relative;
  padding: 0;
  margin: 0;
  gap: 25px;
}

.menu-products {
  position: relative;
  display: inline-block;
  color: #fff;
}

.menu-products:hover > .dropdown-container {
  display: block;
}

.dropdown-list {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  margin-bottom: 0;
  background-color: rgb(31 41 55);
}

a,
.products {
  cursor: pointer;
}

.logo {
  max-width: 120px;
  aspect-ratio: 5 / 4;
}

.dropdown-container {
  display: none;
  width: 256px;
}

.vr {
  width: 2px;
  background-color: #fff;
}

.language-btn {
  color: #fff;
  cursor: pointer;
}

@media screen and (min-width: 729px) {
  .navbar {
    align-items: center;
    justify-content: space-between;
    padding: 1rem 3rem;
  }

  .dropdown-container {
    position: absolute;
    z-index: 9999999;
    top: 100%;
  }
}

@media screen and (max-width: 728px) {
  .menu {
    width: 100%;
    height: 0;
    opacity: 1;
    transform: all 0.5s ease;
    overflow: hidden;
  }
  .menu.active {
    height: 100%;
  }

  .menu-list {
    flex-direction: column;
    padding: 20px;
  }
  .navbar label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  #toggler:checked ~ .menu {
    max-height: 100%;
  }

  .menu-icon {
    display: block;
    color: #eeeeee;
    width: 2rem;
    height: 2rem;
    margin-right: 2rem;
  }

  .logo {
    margin-left: 1rem;
  }

  .menu-list-item:hover > .dropdown-container {
    height: 100%;
  }

  .vr {
    display: none;
  }
}
