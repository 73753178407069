.container {
  display: flex;
  position: relative;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  z-index: 20;
  width: 100vw;
  min-height: 60vh;
  height: 100%;
}

.success-title {
  font-size: 2rem;
  color: #eeeeee;
  font-family: "RalewayRegular";
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .container {
    height: 70vh;
  }
}
