.full-body {
  position: relative;
  margin: 0;
  padding: 0;
  z-index: 10;
  width: 100vw;
  min-height: 100vh;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  font-family: RalewayRegular;
  font-weight: bold;
  color: #eeeeee;
  text-align: center;
}

.title {
  color: #eeeeee;
}

.all-inputs {
  display: flex;
  flex-direction: column;
  width: 50vw;
}

.inputs {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  column-gap: 4rem;
}

.input-container {
  flex: 1;
  margin-top: 2rem;
}

.input {
  width: 100%;
  background-color: #dddddd;
  padding: 4px;
  height: 3rem;
  font-size: 20px;
  border-radius: 2px;
  text-align: start;
}

.resume-container {
  display: flex;
  width: 100%;
  background-color: #eeeeee;
  border-radius: 2px;
  padding: 8px;
  align-items: center;
}

.resume-container > .title {
  color: black;
  text-transform: none;
  cursor: pointer;
}

.message-input {
  height: 5rem;
}

.button {
  background-color: rgb(96 165 250);
  width: 10rem;
  height: 3rem;
  font-size: 20px;
  color: #eeeeee;
  margin-top: 4rem;
  border-radius: 2px;
  align-self: center;
}

@media only screen and (max-width: 768px) {
  .all-inputs {
    width: 75vw;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
  }
  .input {
    width: 100%;
  }
}
