@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800&display=swap");

* {
  font-family: "Raleway", sans-serif !important;
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
}

hr {
  width: 100%;
}

::-webkit-scrollbar {
  display: none;
}
