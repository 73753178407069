.footer {
  z-index: 20;
}

.carousel-container {
  display: block;
  height: 100%;
  padding: 3rem 0;
  margin: 3rem 0;
}

.carousel-img-container {
  display: block;
  width: 24rem;
  height: 24rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.carousel-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ba-card-image {
  max-width: 50%;
  width: 50%;
}

.tm-images-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.tm-card-image {
  width: 75%;
}

.sub-header-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.dg-background {
  background-image: url("./assets/images/dg/DataGovernanceHeader.jpg");
}

.ba-background {
  background-image: url("./assets/images/ba/BusinessAnalyticsHeader.jpg");
}

.tm-background {
  background-image: url("./assets/images/tm/websolutions.jpg");
  background-position: top !important;
}

.ipa-background {
  background-image: url("./assets/images/ipa/IndustrialProjectApplicationsHeader.jpg");
  background-position: bottom !important;
}

.bd-background {
  background-image: url("./assets/images/bd/BigDataHeader.jpg");
}

.esd-background {
  background-image: url("./assets/images/esd/EmbeddedSystemDesignHeader.jpg");
}

.sfa-background {
  background-image: url("./assets/images/sfa/SmartFactoryApplicationsHeader.jpg");
}

.sgs-background {
  background-image: url("./assets/images/sgs/SmartGridSystemHeader.JPG");
}

.plc-background {
  background-image: url("./assets/images/plc/PLCAutomationsHeader.jpg");
}

.sub-header-text-wrapper {
  background-image: linear-gradient(to right, rgb(31 41 55), lightblue);
  max-width: 50%;
  height: 100%;
  padding: 6rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0 6rem 6rem 0;
}

.ba-card-desc {
  text-align: left;
  max-width: 50%;
}

@media (min-width: 1280px) {
  .sub-header-text-wrapper {
    max-width: 40%;
  }
}

@media (max-width: 1280px) and (min-width: 768px) {
  .sub-header-text-wrapper {
    max-width: 50%;
  }
}

@media (max-width: 768px) {
  .carousel-img-container {
    align-self: center;
    width: 12rem;
    height: 12rem;
  }

  .sub-header-container {
    background-image: none;
  }

  .sub-header-text-wrapper {
    width: 100%;
    max-width: 100%;
    border-radius: 0 0 2rem 2rem;
    align-items: center;
  }

  .ba-card {
    display: flex;
    flex-direction: column !important;
  }

  .ba-card-desc {
    max-width: 100%;
  }

  .ba-card-image {
    width: 90%;
    max-width: 90%;
    margin: 2rem 0;
  }

  .ba-card-desc {
    text-align: center;
  }

  .tm-images-container {
    margin-top: 2rem;
  }
}
