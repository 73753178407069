.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  background-color: rgb(17 24 39);
  padding-top: 1rem;
  font-family: "RalewayRegular";
}

.contact-text {
  font-size: 1.6vw;
  font-weight: bold;
  color: rgb(96 165 250);
  text-align: center;
}

.footer > div {
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: 2rem;
}

.cds-text {
  font-size: 1.5rem;
  color: #eeeeee;
}

@media only screen and (max-width: 768px) {
  .footer > div {
    padding-left: 1rem;
  }

  .contact-text {
    font-size: 1.2rem;
  }

  .cds-text {
    font-size: 1rem;
  }
}
